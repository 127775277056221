import React, { ReactNode, useCallback, useContext } from "react"
import {
  Button,
  createStyles,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  Theme,
} from "@material-ui/core"
import { Lock, LockOpen } from "@material-ui/icons"
import {
  ColorMap,
  ColorRowColor,
  ColorRowOrder,
  QwixxContext,
  QwixxContextAction,
} from "./qwixx-context"
import { makeStyles } from "@material-ui/core/styles"

interface ColorRowProps {
  color: ColorRowColor
}

const buildNumberList = (order: ColorRowOrder) => {
  const list: number[] = []
  for (let i = 2; i <= 12; i++) {
    list.push(i)
  }

  if (order === ColorRowOrder.DESC) {
    return list.reverse()
  }
  return list
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      padding: theme.spacing(1),
    },
    buttonContainer: {
      textAlign: "center",
    },
    lockOther: {
      textAlign: "right",
    },
  })
)

export default function ColorRow(props: ColorRowProps) {
  const { state, dispatch } = useContext(QwixxContext)

  const { color } = props

  const { selected, order } = state.rows[color]
  const locked = state.locks[color]
  const lockedOther = state.lockOthers[color]

  const classes = useStyles()

  const numbers = buildNumberList(order)

  const handleClick = useCallback(
    (value: number) => {
      const thisSelected = selected[value]
      let thisLocked = locked

      const [lastNumber] = numbers.slice(-1)
      let numSelected = 0
      Object.keys(selected).map(value => {
        if (selected[value]) {
          numSelected++
        }
      })

      if (value === lastNumber) {
        if (numSelected < 5) {
          // TODO: some other way to show this message
          alert(
            `Cannot select ${lastNumber} until at least 5 numbers are selected in this row.`
          )
          return
        } else {
          thisLocked = !thisSelected
        }
      }

      selected[value] = !thisSelected
      dispatch({
        type: QwixxContextAction.UPDATE_SELECTED,
        payload: {
          color,
          selected,
        },
      })
      dispatch({
        type: QwixxContextAction.UPDATE_LOCKED,
        payload: {
          color,
          locked: thisLocked,
        },
      })
    },
    [selected, color, dispatch]
  )

  const handleLock = useCallback(() => {
    if (!locked) {
      // can't manually lock
      return
    }
    const [lastNumber] = numbers.slice(-1)
    dispatch({
      type: QwixxContextAction.UPDATE_LOCKED,
      payload: {
        color,
        locked: !locked,
      },
    })
    dispatch({
      type: QwixxContextAction.UPDATE_SELECTED,
      payload: {
        color,
        selected: {
          ...selected,
          [lastNumber]: false,
        },
      },
    })
  }, [selected, locked, color, dispatch])

  const handleLockOther = useCallback(() => {
    dispatch({
      type: QwixxContextAction.UPDATE_LOCKED_OTHER,
      payload: {
        color,
        lockedOther: !lockedOther,
      },
    })
  }, [lockedOther, color, dispatch])

  const buttons: ReactNode[] = []
  let higherSelected = false
  // process in reverse so we can check if leftward numbers should be unselectable
  numbers
    .slice()
    .reverse()
    .forEach((value: number, idx: number) => {
      const thisSelected = selected[value]
      let variant
      if (thisSelected) {
        variant = "contained"
      } else {
        if (locked || lockedOther) {
          variant = "text"
        } else {
          variant = higherSelected ? "text" : "outlined"
        }
      }

      buttons.unshift(
        <Grid
          key={idx}
          item
          xs={3}
          sm={2}
          md={1}
          className={classes.buttonContainer}
        >
          <Button
            variant={variant}
            disabled={
              locked || lockedOther || (higherSelected && !thisSelected)
            }
            onClick={() => {
              handleClick(value)
            }}
          >
            {value}
          </Button>
        </Grid>
      )
      if (!higherSelected && thisSelected) {
        higherSelected = true
      }
    })

  const lockIcon = locked ? <Lock /> : <LockOpen />
  const lock = (
    <IconButton size="small" onClick={handleLock}>
      {lockIcon}
    </IconButton>
  )
  const lockOther = <Switch checked={lockedOther} onClick={handleLockOther} />

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Paper
            elevation={3}
            className={classes.row}
            style={{ backgroundColor: ColorMap[color] }}
          >
            <Grid container alignItems="center">
              {buttons}
              <Grid
                item
                xs={3}
                sm={2}
                md={1}
                className={classes.buttonContainer}
              >
                {lock}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignContent="flex-end">
        <Grid item xs className={classes.lockOther}>
          <FormControlLabel
            control={lockOther}
            label="Locked by other"
            labelPlacement="start"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
