import React, { useCallback, useContext } from "react"
import { QwixxContext, QwixxContextAction } from "./qwixx-context"
import {
  Button,
  Chip,
  createStyles,
  Fab,
  Grid,
  IconButton,
  Theme,
} from "@material-ui/core"
import {
  Close,
  RadioButtonUnchecked,
  Add,
  SentimentVeryDissatisfied,
} from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"

enum PenaltyOp {
  INC,
  DEC,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(1),
    },
    penaltyChip: {
      marginRight: theme.spacing(1),
    },
  })
)

export default function Penalties() {
  const { state, dispatch } = useContext(QwixxContext)

  const { penalties } = state

  const classes = useStyles()

  const handleClick = useCallback(
    (op: PenaltyOp) => {
      let type = QwixxContextAction.ADD_PENALTY
      if (op === PenaltyOp.DEC) {
        type = QwixxContextAction.REMOVE_PENALTY
      }
      dispatch({
        type,
      })
    },
    [dispatch]
  )

  let penaltyChips = []
  for (let i = 0; i < penalties; i++) {
    penaltyChips.push(
      <Chip
        key={i}
        icon={<SentimentVeryDissatisfied />}
        color="secondary"
        className={classes.penaltyChip}
        onDelete={() => {
          handleClick(PenaltyOp.DEC)
        }}
      />
    )
  }

  const addButton = (
    <IconButton
      size="small"
      onClick={() => {
        handleClick(PenaltyOp.INC)
      }}
    >
      <Add />
    </IconButton>
  )

  return (
    <Grid container className={classes.container}>
      <Grid item>
        Penalties: {penaltyChips}
        {penaltyChips.length < 4 && addButton}
      </Grid>
    </Grid>
  )
}
