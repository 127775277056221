import React, { useCallback, useContext, useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import QwixxContextProvider, {
  ColorRowColor,
  QwixxContext,
  QwixxContextAction,
} from "../components/qwixx/qwixx-context"
import ColorRow from "../components/qwixx/color-row"
import {
  Button,
  createStyles,
  Grid,
  Hidden,
  Link,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core"
import Penalties from "../components/qwixx/penalties"
import {
  BlueScoreInput,
  GreenScoreInput,
  RedScoreInput,
  YellowScoreInput,
} from "../components/qwixx/score-input"
import { makeStyles } from "@material-ui/core/styles"
import ReplayIcon from "@material-ui/icons/Replay"

interface ScoreRows {
  [key: ColorRowColor]: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    resetWrapper: {
      textAlign: "right",
    },
    resetFullWidth: {
      width: "100%",
    },
  })
)

export function Qwixx() {
  const { state, dispatch } = useContext(QwixxContext)
  const [scores, setScores] = useState<ScoreRows>({
    [ColorRowColor.RED]: 0,
    [ColorRowColor.YELLOW]: 0,
    [ColorRowColor.GREEN]: 0,
    [ColorRowColor.BLUE]: 0,
  })

  const { rows, locks, penalties } = state

  const classes = useStyles()

  useEffect(() => {
    if (state.timestamp === 0) {
      dispatch({
        type: QwixxContextAction.LOAD_STATE,
      })
    }
  }, [state, dispatch])

  useEffect(() => {
    const s = {
      [ColorRowColor.RED]: 0,
      [ColorRowColor.YELLOW]: 0,
      [ColorRowColor.GREEN]: 0,
      [ColorRowColor.BLUE]: 0,
    }
    Object.keys(rows).map(color => {
      const isLocked = locks[color]
      if (isLocked) {
        s[color] += 1
      }
      const row = rows[color]
      let multiple = isLocked ? 2 : 1
      Object.keys(row.selected).map(value => {
        if (row.selected[value]) {
          s[color] += multiple

          multiple++
        }
      })
    })
    setScores(s)
  }, [rows, locks])

  const resetState = useCallback(() => {
    dispatch({
      type: QwixxContextAction.RESET_STATE,
      payload: 1,
    })
  }, [dispatch])

  const colorRows = []
  Object.entries(rows).map(([color, r], idx) => {
    colorRows.push(<ColorRow key={idx} color={color} />)
  })

  const redScore = (
    <RedScoreInput
      variant="outlined"
      label="Red"
      value={scores[ColorRowColor.RED]}
      fullWidth
    />
  )
  const yellowScore = (
    <YellowScoreInput
      variant="outlined"
      label="Yellow"
      value={scores[ColorRowColor.YELLOW]}
      fullWidth
    />
  )
  const greenScore = (
    <GreenScoreInput
      variant="outlined"
      label="Green"
      value={scores[ColorRowColor.GREEN]}
      fullWidth
    />
  )
  const blueScore = (
    <BlueScoreInput
      variant="outlined"
      label="Blue"
      value={scores[ColorRowColor.BLUE]}
      fullWidth
    />
  )
  const totalPenalties = penalties * -5
  const penaltyScore = (
    <TextField
      variant="outlined"
      label="Penalties"
      value={totalPenalties}
      fullWidth
    />
  )
  let totalScore = totalPenalties
  Object.keys(scores).map(color => {
    totalScore += scores[color]
  })

  return (
    <Layout>
      <SEO
        title="Qwixx Score Sheet"
        description="A digital version for scoring the dice game Qwixx"
      />
      <Typography variant="h2" component="h1" gutterBottom={true}>
        Qwixx Score Sheet
      </Typography>
      <Paper elevation={3} className={classes.description}>
        <Typography variant="body1">
          As you are playing Qwixx, click the buttons to mark your selections. A
          row will be locked when you have at least five selected numbers in a
          row, and you are selecting the furthest number on the right.
          <br />
          Optionally, you may lock a row if another player has locked it.
          <br />
          <br />
          You board will be saved if you reload the page. You can reset your
          board by clicking the "Reset" button.
          <br />
          If you open this page in a new tab or window, it will start with a new
          board.
          <br />
          <br />
          Qwixx can be purchased from{" "}
          <Link href="https://gamewright.com/product/Qwixx" color="secondary">
            Gamewright
          </Link>
          .
        </Typography>
      </Paper>
      {colorRows}
      <Penalties />
      <Grid container spacing={1}>
        <Grid item xs={6} md={2}>
          {redScore}
        </Grid>
        <Grid item xs={6} md={2}>
          {yellowScore}
        </Grid>
        <Grid item xs={6} md={2}>
          {greenScore}
        </Grid>
        <Grid item xs={6} md={2}>
          {blueScore}
        </Grid>
        <Grid item xs={6} md={2}>
          {penaltyScore}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4">Total Score: {totalScore}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.resetWrapper}>
          <Hidden only="xs">
            <Button
              variant="contained"
              startIcon={<ReplayIcon />}
              onClick={resetState}
            >
              Reset
            </Button>
          </Hidden>
          <Hidden smUp>
            <Button
              variant="contained"
              startIcon={<ReplayIcon />}
              onClick={resetState}
              className={classes.resetFullWidth}
            >
              Reset
            </Button>
          </Hidden>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default function QwixxWithContext() {
  return (
    <QwixxContextProvider>
      <Qwixx />
    </QwixxContextProvider>
  )
}
