import React, {
  createContext,
  Dispatch,
  ReactNode,
  useEffect,
  useReducer,
} from "react"
import { amber, blue, green, red } from "@material-ui/core/colors"

export const ColorRed = red[800]
export const ColorYellow = amber[700]
export const ColorGreen = green[800]
export const ColorBlue = blue[600]

export interface RowItems {
  [key: number]: boolean
}

export interface RowData {
  selected: RowItems
  locked: boolean
  lockedOther: boolean
}

interface Row {
  selected: RowItems
  order: ColorRowOrder
}

export enum ColorRowOrder {
  ASC,
  DESC,
}

export enum ColorRowColor {
  RED,
  YELLOW,
  GREEN,
  BLUE,
}

export const ColorMap = {
  [ColorRowColor.RED]: ColorRed,
  [ColorRowColor.YELLOW]: ColorYellow,
  [ColorRowColor.GREEN]: ColorGreen,
  [ColorRowColor.BLUE]: ColorBlue,
}

interface QwixxState {
  timestamp: number
  rows: { [key: ColorRowColor]: Row }
  locks: { [key: ColorRowColor]: boolean }
  lockOthers: { [key: ColorRowColor]: boolean }
  penalties: number
}

export enum QwixxContextAction {
  UPDATE_SELECTED,
  UPDATE_LOCKED,
  UPDATE_LOCKED_OTHER,
  ADD_PENALTY,
  REMOVE_PENALTY,
  RESET_STATE,
  LOAD_STATE,
}

interface Action {
  type: QwixxContextAction
  payload: any
}

const initialState: QwixxState = {
  timestamp: 0,
  rows: {
    [ColorRowColor.RED]: {
      selected: {},
      order: ColorRowOrder.ASC,
    },
    [ColorRowColor.YELLOW]: {
      selected: {},
      order: ColorRowOrder.ASC,
    },
    [ColorRowColor.GREEN]: {
      selected: {},
      order: ColorRowOrder.DESC,
    },
    [ColorRowColor.BLUE]: {
      selected: {},
      order: ColorRowOrder.DESC,
    },
  },
  locks: {
    [ColorRowColor.RED]: false,
    [ColorRowColor.YELLOW]: false,
    [ColorRowColor.GREEN]: false,
    [ColorRowColor.BLUE]: false,
  },
  lockOthers: {
    [ColorRowColor.RED]: false,
    [ColorRowColor.YELLOW]: false,
    [ColorRowColor.GREEN]: false,
    [ColorRowColor.BLUE]: false,
  },
  penalties: 0,
}

const STATE_KEY = "qwixx-state"

const reducer = (state: QwixxState, action: Action): QwixxState => {
  switch (action.type) {
    case QwixxContextAction.UPDATE_SELECTED:
      return {
        ...state,
        rows: {
          ...state.rows,
          [action.payload.color]: {
            ...state.rows[action.payload.color],
            selected: action.payload.selected,
          },
        },
      }
    case QwixxContextAction.UPDATE_LOCKED:
      return {
        ...state,
        locks: {
          ...state.locks,
          [action.payload.color]: action.payload.locked,
        },
      }
    case QwixxContextAction.UPDATE_LOCKED_OTHER:
      return {
        ...state,
        lockOthers: {
          ...state.lockOthers,
          [action.payload.color]: action.payload.lockedOther,
        },
      }
    case QwixxContextAction.ADD_PENALTY:
      return {
        ...state,
        penalties: ++state.penalties,
      }
    case QwixxContextAction.REMOVE_PENALTY:
      return {
        ...state,
        penalties: --state.penalties,
      }
    case QwixxContextAction.RESET_STATE:
      return {
        ...initialState,
        rows: {
          [ColorRowColor.RED]: {
            ...initialState.rows[ColorRowColor.RED],
            selected: {},
          },
          [ColorRowColor.YELLOW]: {
            ...initialState.rows[ColorRowColor.YELLOW],
            selected: {},
          },
          [ColorRowColor.GREEN]: {
            ...initialState.rows[ColorRowColor.GREEN],
            selected: {},
          },
          [ColorRowColor.BLUE]: {
            ...initialState.rows[ColorRowColor.BLUE],
            selected: {},
          },
        },
      }
    case QwixxContextAction.LOAD_STATE:
      if (typeof window !== "undefined") {
        const sessionJson = window.sessionStorage.getItem(STATE_KEY)
        if (sessionJson) {
          const session = JSON.parse(sessionJson)
          session.timestamp = Date.now()
          return session
        }
      } else {
        return { ...initialState }
      }
  }

  return state
}

export const QwixxContext = createContext<{
  state: QwixxState
  dispatch: Dispatch<any>
}>({
  state: initialState,
  dispatch: () => null,
})

interface QwixxContextProviderProps {
  children: ReactNode | ReactNode[]
}

export default function QwixxContextProvider(props: QwixxContextProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (typeof window !== "undefined") {
      // save state to session storage
      window.sessionStorage.setItem(STATE_KEY, JSON.stringify(state))
    }
  }, [state])

  return (
    <QwixxContext.Provider value={{ state, dispatch }}>
      {props.children}
    </QwixxContext.Provider>
  )
}
