import React from "react"
import { TextField, withStyles } from "@material-ui/core"
import { ColorMap, ColorRowColor } from "./qwixx-context"

const makeInput = (color: ColorRowColor) => {
  const border = {
    borderColor: ColorMap[color],
  }
  const styleColor = {
    color: ColorMap[color],
  }
  return withStyles({
    root: {
      "& label": styleColor,
      "& label.Mui-focused": styleColor,
      "& .MuiOutlinedInput-root": {
        "& fieldset": border,
        "&:hover fieldset": border,
        "&.Mui-focused fieldset": border,
      },
    },
  })(TextField)
}

export const RedScoreInput = makeInput(ColorRowColor.RED)
export const YellowScoreInput = makeInput(ColorRowColor.YELLOW)
export const GreenScoreInput = makeInput(ColorRowColor.GREEN)
export const BlueScoreInput = makeInput(ColorRowColor.BLUE)
